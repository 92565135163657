import React from 'react';

import { graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HelmetComponent from '../components/helmetcomponent';
import Layout from '../components/layout';
import VerticalDonationBar from '../components/verticaldonationbar';
import '../template-styles/giving-tuesday-2023.scss';

export default function Template({ data }) {
  const pageData = data.allContentfulGivingTuesday2023.edges[0].node;
  const {
    bannerEmbeddedUrl,
    bannerImageThumbnail,
    bodyTitle,
    bodyDescription,
    videoDescription,
    buttonDonateToday,
    goalAmount: currentAmount,
    goalTotalAmount
  } = pageData;

  const videoDescriptionOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="video-description">{children}</p>
    }
  };

  return (
    <ParallaxProvider>
      <Layout nav="colored-nav">
        <HelmetComponent title="Giving Tuesday" />
        <section className="gt23-banner">
          <img src={bannerImageThumbnail.file.url} alt="giving-tuesday" id="thumbnail" />
        </section>

        <section className="gt23-body">
          <div className="body-container">
            <div className="progress-section">
              <VerticalDonationBar currentAmount={currentAmount} totalAmount={goalTotalAmount} />
              <div className="giving-tuesday-logo-container">
                <div className="giving-tuesday-logo" />
              </div>
            </div>
            <div className="text-section">
              <h1 className="title">{bodyTitle.bodyTitle}</h1>
              <p className="description">{bodyDescription.childMarkdownRemark.rawMarkdownBody}</p>
            </div>
          </div>

          <div className="embed-container">
            <iframe
              id="giving-tuesday-2023-video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              data-src={bannerEmbeddedUrl}
              title="Pearls of Value"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>

          <div className="videoDescription">
            {documentToReactComponents(videoDescription.json, videoDescriptionOptions)}
          </div>
          <a className="donate-link" data-formid="34227">
            {buttonDonateToday}
          </a>
        </section>
      </Layout>
    </ParallaxProvider>
  );
}

export const GivingTuesday2023PageQuery = graphql`
  query GivingTuesday2023Page($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulGivingTuesday2023 {
      totalCount
      edges {
        node {
          bannerEmbeddedUrl
          bannerImageThumbnail {
            file {
              url
            }
          }
          title
          bodyDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          bodyTitle {
            bodyTitle
          }
          videoDescription {
            json
          }
          buttonDonateToday
          goalAmount
          goalTotalAmount
        }
      }
    }
  }
`;
